import React from 'react'
import { graphql, navigate, PageProps } from 'gatsby'

import styled from 'styled-components'

import media from 'styles/media'

import Layout from 'components/layout/layout'
import Header from 'components/sections/main-header'
import Newsletter from 'components/sections/newsletter'

import { ArticleTemplateQuery } from 'types/graphql'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from 'components/layout/seo'

const StyledArticleContentWrapper = styled.div`
  max-width: 1100px;
  padding: 20px 20px 0 20px;
  line-height: 2.5;
  margin: 0 auto;

  ${media.lg.min} {
    padding: 20px 50px 0 50px;
  }

  p {
    margin-bottom: 15px;
  }
`

const StyledNavigation = styled.div`
  max-width: 1100px;
  padding: 40px 20px 0 20px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;

  ${media.lg.min} {
    flex-direction: row;
    gap: 0;
    padding: 60px 50px 0 50px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: calc(100% - 100px);
    background-color: lightgray;
  }
`

const StyledBackButton = styled.button`
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
`

const StyledBackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 20px;

  &:hover {
    cursor: pointer;

    ${StyledBackButton} {
      background-color: ${({ theme }) => theme.colors.orange};
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const StyledBackButtonDescription = styled.p``

const StyledNextButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;

  ${media.lg.min} {
    top: auto;
    bottom: 20px;
  }
`

const StyledNextNewsCard = styled.div`
  flex-basis: 50%;
  position: relative;
  padding: 30px;
  cursor: pointer;

  &:hover {
    ${StyledNextButton} {
      background-color: ${({ theme }) => theme.colors.orange};
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  border-radius: 29px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    background: linear-gradient(
      to top,
      white,
      rgba(255, 255, 255, 0.45),
      white
    );
  }

  & > img {
    object-fit: cover;
    object-position: 0% 0%;
  }
`

const StyledDate = styled.p`
  font-size: 14px;
`
const StyledLocation = styled.p`
  margin-top: 20px;
  font-size: 14px;
`
const StyledEventName = styled.p`
  line-height: 1.1;
  margin-top: 25px;
  font-size: 24px;
  font-weight: bold;
`
const StyledSummary = styled.p`
  margin-top: 30px;
  line-height: 1.5;
`

const ArticleTemplate: React.FC<PageProps<ArticleTemplateQuery>> = ({
  data,
}) => {
  return (
    <Layout>
      <Seo
        title={data?.wpPost?.seo?.title!}
        description={data?.wpPost?.seo?.metaDesc!}
        ogTitle={data?.wpPost?.seo?.opengraphTitle!}
        ogDescription={data?.wpPost?.seo?.opengraphDescription!}
        ogImage={data?.wpPost?.seo?.opengraphImage?.sourceUrl!}
      />
      <Header
        title={data?.wpPost?.title!}
        image={{
          src: data?.wpPost?.aktualnosci?.newsHeroImage?.localFile
            ?.childImageSharp?.gatsbyImageData!,
          alt: data?.wpPost?.aktualnosci?.newsHeroImage?.altText!,
        }}
        noActionButton
        noRadialGradient
      />

      <StyledArticleContentWrapper
        dangerouslySetInnerHTML={{
          __html: data?.wpPost?.content,
        }}
      />

      <StyledNavigation>
        <StyledBackButtonWrapper
          onClick={() => navigate(`/kim-jestesmy#aktualnosci`)}
        >
          <StyledBackButton>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="20.001"
              viewBox="0 0 11 20.001"
            >
              <path
                id="arrow"
                d="M259.633,6538.71a1.062,1.062,0,0,1-1.464,0l-8.563-8.264a1.951,1.951,0,0,1,0-2.828l8.625-8.325a1.063,1.063,0,0,1,1.454-.01.976.976,0,0,1,.011,1.425l-7.894,7.617a.975.975,0,0,0,0,1.414l7.831,7.558a.974.974,0,0,1,0,1.413"
                transform="translate(-248.999 -6519.001)"
                fill="#ff9d00"
                fill-rule="evenodd"
              />
            </svg>
          </StyledBackButton>
          <StyledBackButtonDescription>
            Wróć do listy
          </StyledBackButtonDescription>
        </StyledBackButtonWrapper>
        {data?.NEXT && (
          <StyledNextNewsCard
            onClick={() => navigate(`/aktualnosci/${data?.NEXT?.slug}`)}
          >
            <StyledGatsbyImage
              image={
                data.NEXT?.aktualnosci?.newsHeroImage?.localFile
                  ?.childImageSharp?.gatsbyImageData!
              }
              alt={data?.NEXT?.aktualnosci?.newsHeroImage?.altText!}
            />
            <StyledDate>
              {data?.NEXT?.date.split('T')[0].split('-').reverse().join('.')}
            </StyledDate>
            <StyledLocation>
              {data?.NEXT?.aktualnosci?.newsInvestment}
            </StyledLocation>
            <StyledEventName>
              {data?.NEXT?.aktualnosci?.newsTitle}
            </StyledEventName>
            <StyledSummary>
              {data?.NEXT?.aktualnosci?.newsSummary}
            </StyledSummary>
            <StyledNextButton aria-label="następny artykuł">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="20.001"
                viewBox="0 0 11 20.001"
              >
                <path
                  id="arrow"
                  d="M249.366,6538.71a1.062,1.062,0,0,0,1.464,0l8.563-8.264a1.951,1.951,0,0,0,0-2.828l-8.625-8.325a1.063,1.063,0,0,0-1.454-.01.976.976,0,0,0-.011,1.425l7.894,7.617a.975.975,0,0,1,0,1.414l-7.831,7.558a.974.974,0,0,0,0,1.413"
                  transform="translate(-249 -6519.001)"
                  fill="#ff9d00"
                  fill-rule="evenodd"
                />
              </svg>
            </StyledNextButton>
          </StyledNextNewsCard>
        )}
      </StyledNavigation>

      <Newsletter withImage womanImage manImage />
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($slug: String, $next: String) {
    wpPost(slug: { eq: $slug }) {
      slug
      title
      date
      content
      aktualnosci {
        newsSummary
        newsTitle
        newsInvestment
        newsHeroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
          altText
        }
      }
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    }
    NEXT: wpPost(slug: { eq: $next }) {
      slug
      title
      date
      content
      aktualnosci {
        newsSummary
        newsTitle
        newsInvestment
        newsHeroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
        }
      }
    }
  }
`
